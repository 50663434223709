import { gql } from "@apollo/client"

export const FIND_UNIQUE_BLOG = gql`
	query(
		$where: BlogWhereUniqueInput!
	){
		findUniqueBlog(
			where: $where
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
export const FIND_FIRST_BLOG = gql`
	query(
		$where: BlogWhereInput
		$orderBy: [BlogOrderByWithRelationInput]
		$cursor: BlogWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [BlogScalarFieldEnum]
	){
		findFirstBlog(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
export const FIND_MANY_BLOG = gql`
	query(
		$where: BlogWhereInput
		$orderBy: [BlogOrderByWithRelationInput]
		$cursor: BlogWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [BlogScalarFieldEnum]
	){
		findManyBlog(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
export const CREATE_ONE_BLOG = gql`
	mutation(
		$data: BlogCreateInput!
	){
		createOneBlog(
			data: $data
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
export const UPDATE_ONE_BLOG = gql`
	mutation(
		$data: BlogUpdateInput!
		$where: BlogWhereUniqueInput!
	){
		updateOneBlog(
			data: $data
			where: $where
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
export const DELETE_ONE_BLOG = gql`
	mutation(
		$where: BlogWhereUniqueInput!
	){
		deleteOneBlog(
			where: $where
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
export const UPSERT_ONE_BLOG = gql`
	mutation(
		$where: BlogWhereUniqueInput!
		$create: BlogCreateInput!
		$update: BlogUpdateInput!
	){
		upsertOneBlog(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			title
			topic
			img
			description
			images
		}
	}
`
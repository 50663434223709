import { gql } from "@apollo/client"

export const FIND_UNIQUE_CITIES = gql`
	query(
		$where: CitiesWhereUniqueInput!
	){
		findUniqueCities(
			where: $where
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const FIND_FIRST_CITIES = gql`
	query(
		$where: CitiesWhereInput
		$orderBy: [CitiesOrderByWithRelationInput]
		$cursor: CitiesWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CitiesScalarFieldEnum]
	){
		findFirstCities(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const FIND_MANY_CITIES = gql`
	query(
		$where: CitiesWhereInput
		$orderBy: [CitiesOrderByWithRelationInput]
		$cursor: CitiesWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CitiesScalarFieldEnum]
	){
		findManyCities(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const CREATE_ONE_CITIES = gql`
	mutation(
		$data: CitiesCreateInput!
	){
		createOneCities(
			data: $data
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const UPDATE_ONE_CITIES = gql`
	mutation(
		$data: CitiesUpdateInput!
		$where: CitiesWhereUniqueInput!
	){
		updateOneCities(
			data: $data
			where: $where
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const DELETE_ONE_CITIES = gql`
	mutation(
		$where: CitiesWhereUniqueInput!
	){
		deleteOneCities(
			where: $where
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const UPSERT_ONE_CITIES = gql`
	mutation(
		$where: CitiesWhereUniqueInput!
		$create: CitiesCreateInput!
		$update: CitiesUpdateInput!
	){
		upsertOneCities(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			title
			description
			images
			country{
				id
				isVisible
				title
				description
				currency
				locale
				images
				_count{
					City
				}
			}
			countryId
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectType{
					id
					isVisible
					title
				}
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
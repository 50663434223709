import { useEffect } from 'react'
import { Button, Form, message, Input, InputNumber } from 'antd'
import {Top} from '../../components/Top'
import { useMutation } from '@apollo/client'
import { useUser } from '../../utils/hooks'

import { useNavigate } from 'react-router-dom'
import { CREATE_ONE_OBJECT_TYPE } from '../../gqls/objecttype'

const { TextArea } = Input

const requiredRule = { required: true, message: 'Обязательное поле' }
const numberRule = { type: 'number', message: 'Введите число от 1 до 5' }

const CreateObjectType = () => {
  const { user, loading: loadingMe } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.role?.canObject) navigate(-1)
  })

  const [createOne, { loading }] = useMutation(CREATE_ONE_OBJECT_TYPE, {
    onCompleted: () => navigate(-1),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const handleCreate = (v) => {
    createOne({ variables: { data: { ...v } } })
  }

  if (loadingMe) return 'Загрузка...'
  if (!user?.role?.canObject) return 'Ошибка'

  return (
    <>
      <Top title='Добавить тип объекта' />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Заголовок' required>
            <Input />
          </Form.Item>
          <Button loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateObjectType

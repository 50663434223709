import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_APPLICATION, FIND_MANY_REVIEW } from '../../gqls'

const limit = 50
const Application = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canReview) navigate(-1)
  })

  const { data, loading } = useQuery(FIND_MANY_APPLICATION, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyApplication : []
  const total = data ? data?.findManyApplication.length : 0
  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Телефон',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Время звонка',
      dataIndex: 'callTime',
      key: 'callTime',
    },
    // {
    //   title: 'Интересный объект',
    //   dataIndex: 'objectOfInterest',
    //   key: 'objectOfInterest',
    //   render: (record) => <a href={`http://tuimaadare.com/en/objects/${record}`}>Изменить</a>,
    // },
    {
      title: 'Способ связи',
      dataIndex: 'commuticationType',
      key: 'commuticationType',
    },
  ]

  return (
    <>
      <Top title='Лиды' />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default Application

import { gql } from "@apollo/client"

export const FIND_UNIQUE_ADMIN_ROLE = gql`
	query(
		$where: AdminRoleWhereUniqueInput!
	){
		findUniqueAdminRole(
			where: $where
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
export const FIND_FIRST_ADMIN_ROLE = gql`
	query(
		$where: AdminRoleWhereInput
		$orderBy: [AdminRoleOrderByWithRelationInput]
		$cursor: AdminRoleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminRoleScalarFieldEnum]
	){
		findFirstAdminRole(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
export const FIND_MANY_ADMIN_ROLE = gql`
	query(
		$where: AdminRoleWhereInput
		$orderBy: [AdminRoleOrderByWithRelationInput]
		$cursor: AdminRoleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminRoleScalarFieldEnum]
	){
		findManyAdminRole(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
export const CREATE_ONE_ADMIN_ROLE = gql`
	mutation(
		$data: AdminRoleCreateInput!
	){
		createOneAdminRole(
			data: $data
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
export const UPDATE_ONE_ADMIN_ROLE = gql`
	mutation(
		$data: AdminRoleUpdateInput!
		$where: AdminRoleWhereUniqueInput!
	){
		updateOneAdminRole(
			data: $data
			where: $where
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
export const DELETE_ONE_ADMIN_ROLE = gql`
	mutation(
		$where: AdminRoleWhereUniqueInput!
	){
		deleteOneAdminRole(
			where: $where
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
export const UPSERT_ONE_ADMIN_ROLE = gql`
	mutation(
		$where: AdminRoleWhereUniqueInput!
		$create: AdminRoleCreateInput!
		$update: AdminRoleUpdateInput!
	){
		upsertOneAdminRole(
			where: $where
			create: $create
			update: $update
		){
			id
			title
			description
			canObject
			canBlog
			canReview
			canChangeRole
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_count{
				admin
			}
		}
	}
`
import { useEffect } from 'react'
import { Button, Form, message, Input, InputNumber } from 'antd'
import {Top} from '../../components/Top'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../utils/hooks'

import { useNavigate, useParams } from 'react-router-dom'
import { DELETE_ONE_COUNTRY, FIND_MANY_COUNTRY, UPDATE_ONE_COUNTRY } from '../../gqls/country'

const { TextArea } = Input

const UpdateCountry = () => {
  // const { id } = match.params
  const { user, loading: loadingMe } = useUser()

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.role?.canObject) navigate(-1)
  })

  const { data, loading: loadingAdmin } = useQuery(FIND_MANY_COUNTRY, {
    fetchPolicy: 'network-only',
    variables: { where: { id: { equals: id } } },
  })

  const onCompleted = () => navigate(-1)

  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [updateOneCountry, { loading }] = useMutation(UPDATE_ONE_COUNTRY, {
    onCompleted,
    onError,
  })

  const [deleteOneCountry, { loading: loadingDel }] = useMutation(DELETE_ONE_COUNTRY, {
    onCompleted,
    onError,
  })

  const handleCreate = (v) => {
    updateOneCountry({
      variables: {
        where: { id },
        data: { 
          description: {set:v.description },
          locale: {set:v.locale },
          currency: {set:v.currency },
          title: {set:v.title }
         },
      },
    })
  }

  const deleteAdmin = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneCountry({ variables: { where: { id } } })
  }

  if (loadingMe || loadingAdmin) return 'Загрузка...'
  if (!user?.role?.canReview) return 'Ошибка'
  const admin = data?.findManyCountry[0]

  return (
    <>
      <Top title='Изменить данные' />
      <div style={{ maxWidth: 500 }}>
        <Form initialValues={{ ...admin }} onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Заголовок' required>
            <Input />
          </Form.Item>
          <Form.Item name='description' label='Описание' required>
            <TextArea />
          </Form.Item>
          <Form.Item name='locale' label='Язык' required>
            <Input />
          </Form.Item>
          <Form.Item name='currency' label='Валюта' required>
            <Input />
          </Form.Item>

          <Button loading={loading || loadingDel} htmlType='submit' type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel} onClick={deleteAdmin} type='primary'>
            Удалить
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdateCountry

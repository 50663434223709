import { gql } from "@apollo/client"

export const SIGN_IN_ADMIN = gql`
	mutation(
		$data: AdminSignInInput!
	){
		signInAdmin(
			data: $data
		){
			admin{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				role{
					id
					title
					description
					canObject
					canBlog
					canReview
					canChangeRole
				}
				roleId
			}
			token
		}
	}
`
import { useEffect } from 'react'
import { Button, Form, message, Input, Checkbox } from 'antd'
import {Top} from '../../components/Top'
import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../utils/hooks'
import { DELETE_ONE_ADMIN_ROLE, FIND_UNIQUE_ADMIN_ROLE, UPDATE_ONE_ADMIN_ROLE } from '../../gqls'
import { useParams, useNavigate} from 'react-router-dom'
const { TextArea } = Input

const UpdateRole = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { user, loading: loadingMe } = useUser()

  useEffect(() => {
    if (!user?.role?.canChangeRole) navigate(-1)
  })

  const { data, loading: loadingRole } = useQuery(FIND_UNIQUE_ADMIN_ROLE, {
    fetchPolicy: 'network-only',
    variables: { where: { id } },
  })

  const onCompleted = () => navigate(-1)
  const onError = (err) => {
    console.error(err)
    message.error('Не удалось выполнить запрос')
  }

  const [updateOneAdminRole, { loading }] = useMutation(UPDATE_ONE_ADMIN_ROLE, { onCompleted, onError })

  const [deleteOneAdminRole, { loading: loadingDel }] = useMutation(DELETE_ONE_ADMIN_ROLE, { onCompleted, onError })

  const handleCreate = (v) => {
    const obj = {}
    for (let key of Object.keys(v)) {
      obj[key] = { set: v[key] }
    }
    updateOneAdminRole({ variables: { where: { id }, data: obj } })
  }

  const deleteAdminRole = () => {
    let isRight = window.confirm('Вы уверены, что хотите удалить данные?')
    if (isRight) deleteOneAdminRole({ variables: { where: { id } } })
  }

  if (loadingMe || loadingRole) return 'Загрузка...'
  if (!user?.role?.canChangeRole) return 'Ошибка'
  const role = data?.findUniqueAdminRole

  return (
    <>
      <Top title='Изменить роль' />
      <div style={{ maxWidth: 700 }}>
        <Form onFinish={handleCreate} layout='vertical' initialValues={role}>
          <Form.Item name='title' label='Название' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' label='Описание'>
            <TextArea rows={4} />
          </Form.Item>
          <h3>Права:</h3>
   
          <Form.Item valuePropName='checked' name='canObject'>
            <Checkbox>К объектам</Checkbox>
          </Form.Item>
          <Form.Item valuePropName='checked' name='canBlog'>
            <Checkbox>К блогу</Checkbox>
          </Form.Item>
          <Form.Item valuePropName='checked' name='canReview'>
            <Checkbox>К отзывам</Checkbox>
          </Form.Item>
          <Form.Item valuePropName='checked' name='canChangeRole'>
            <Checkbox>К админам</Checkbox>
          </Form.Item>

          <Button loading={loading} htmlType='submit' type='primary'>
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button danger ghost loading={loading || loadingDel} onClick={deleteAdminRole} type='primary'>
            Удалить
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdateRole;

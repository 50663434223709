import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/ru_RU";

import { GlobalStyles } from "./components";

import moment from "moment";
import "moment/locale/ru";

import apolloClient from "./utils/apollo";

import Layout from "./pages/layout";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";


import Admins from "./pages/admins";
import CreateOneAdmin from "./pages/admins/create";
import UpdateAdmin from "./pages/admins/update";


import Roles from "./pages/roles";
import CreateRole from "./pages/roles/create";
import UpdateRole from "./pages/roles/update";
import Reviews from "./pages/reviews";
import CreateReview from "./pages/reviews/create";
import UpdateReview from "./pages/reviews/update";
import Blogs from "./pages/blogs";
import CreateBlog from "./pages/blogs/create";
import UpdateBlog from "./pages/blogs/update";
import Objects from "./pages/objects";
import CreateObject from "./pages/objects/create";
import UpdateObject from "./pages/objects/update";
import Application from "./pages/application";
import City from "./pages/city";
import CreateCity from "./pages/city/create";
import UpdateCity from "./pages/city/update";
import Country from "./pages/country";
import CreateCountry from "./pages/country/create";
import UpdateCountry from "./pages/country/update";
import ObjectType from "./pages/objectType";
import CreateObjectType from "./pages/objectType/create";
import UpdateObjectType from "./pages/objectType/update";

// import Policy from "./pages/docs/policy";
// import Agreement from "./pages/docs/agreement";
// import Processing from "./pages/docs/processing";


moment.locale("ru");

const App = () => {
  return (
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider locale={locale}>
          <Router>
            <Routes>
              <Route
                path="/"
                exact
                element={
                  <Layout roles={["all"]}>
                    <HomePage />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route path="/login" exact element={<LoginPage />} />
              {/* <Route
                path="/client"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UsersList />
                  </Layout> // default permossion is ['admin']
                }
              /> */}

              <Route
                path="/reviews"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Reviews />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/reviews/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateReview />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/reviews/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateReview />
                  </Layout> // default permossion is ['admin']
                }
              />

              <Route
                path="/admins"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Admins />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateOneAdmin />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateAdmin />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/roles"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Roles />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/roles/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateRole />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/admins/roles/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateRole />
                  </Layout> // default permossion is ['admin']
                }
              />

              <Route
                path="/blogs"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Blogs />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/blogs/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateBlog />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/blogs/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateBlog />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/application"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Application />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/objects"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Objects />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/objects/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateObject />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/objects/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateObject />
                  </Layout> // default permossion is ['admin']
                }
              />
              {/*  */}
              <Route
                path="/cities/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <City />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/cities/create/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateCity />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/cities/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateCity />
                  </Layout> // default permossion is ['admin']
                }
              />
              {/*  */}
              <Route
                path="/country"
                exact
                element={
                  <Layout roles={["all"]}>
                    <Country />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/country/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateCountry />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/country/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateCountry />
                  </Layout> // default permossion is ['admin']
                }
              />
              {/*  */}
              <Route
                path="/objecttype"
                exact
                element={
                  <Layout roles={["all"]}>
                    <ObjectType />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/objecttype/create"
                exact
                element={
                  <Layout roles={["all"]}>
                    <CreateObjectType />
                  </Layout> // default permossion is ['admin']
                }
              />
              <Route
                path="/objecttype/update/:id"
                exact
                element={
                  <Layout roles={["all"]}>
                    <UpdateObjectType />
                  </Layout> // default permossion is ['admin']
                }
              />

              {/* <Route path="/policy" exact element={<Policy />} /> */}
              {/* <Route path="/processing" exact element={<Processing />} /> */}
              {/* <Route path="/agreement" exact element={<Agreement />} /> */}
            </Routes>
          </Router>
          <GlobalStyles />
        </ConfigProvider>
      </ApolloProvider>
    </StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

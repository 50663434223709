import { gql } from "@apollo/client"

export const FIND_UNIQUE_APPLICATION = gql`
	query(
		$where: ApplicationWhereUniqueInput!
	){
		findUniqueApplication(
			where: $where
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
export const FIND_FIRST_APPLICATION = gql`
	query(
		$where: ApplicationWhereInput
		$orderBy: [ApplicationOrderByWithRelationInput]
		$cursor: ApplicationWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ApplicationScalarFieldEnum]
	){
		findFirstApplication(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
export const FIND_MANY_APPLICATION = gql`
	query(
		$where: ApplicationWhereInput
		$orderBy: [ApplicationOrderByWithRelationInput]
		$cursor: ApplicationWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ApplicationScalarFieldEnum]
	){
		findManyApplication(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
export const CREATE_ONE_APPLICATION = gql`
	mutation(
		$data: ApplicationCreateInput!
	){
		createOneApplication(
			data: $data
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
export const UPDATE_ONE_APPLICATION = gql`
	mutation(
		$data: ApplicationUpdateInput!
		$where: ApplicationWhereUniqueInput!
	){
		updateOneApplication(
			data: $data
			where: $where
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
export const DELETE_ONE_APPLICATION = gql`
	mutation(
		$where: ApplicationWhereUniqueInput!
	){
		deleteOneApplication(
			where: $where
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
export const UPSERT_ONE_APPLICATION = gql`
	mutation(
		$where: ApplicationWhereUniqueInput!
		$create: ApplicationCreateInput!
		$update: ApplicationUpdateInput!
	){
		upsertOneApplication(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			name
			number
			email
			callTime
			objectOfInterest
		}
	}
`
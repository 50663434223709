import { useEffect, useState } from "react";
import { Button, Form, message, Input } from "antd";
import { Top } from "../../components/Top";
import { useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../utils/hooks";

import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_ONE_BLOG,
  FIND_MANY_BLOG,
  SINGLE_UPLOAD,
  UPDATE_ONE_BLOG,
} from "../../gqls";

const { TextArea } = Input

const UpdateBlog = () => {
  // const { id } = match.params
  const { user, loading: loadingMe } = useUser();

  const { id } = useParams();
  const navigate = useNavigate();

  const [image, setImage] = useState(null)
  const [images, setImages] = useState([])

  useEffect(() => {
    if (!user?.role?.canBlog) navigate(-1);
  });

  const { data, loading: loadingAdmin } = useQuery(FIND_MANY_BLOG, {
    fetchPolicy: "network-only",
    variables: { where: { id: { equals: id } } },
    onCompleted:(data)=>{
      setImage(data.findManyBlog[0].img)
      setImages(data.findManyBlog[0].images)
    }
  });

  const onCompleted = () => navigate(-1);

  const onError = (err) => {
    console.error(err);
    message.error("Не удалось выполнить запрос");
  };

  const [updateOneBlog, { loading }] = useMutation(UPDATE_ONE_BLOG, {
    onCompleted,
    onError,
  });

  const [deleteOneBlog, { loading: loadingDel }] = useMutation(
    DELETE_ONE_BLOG,
    {
      onCompleted,
      onError,
    }
  );

  const [upload] = useMutation(SINGLE_UPLOAD)

  const handleImage = (event) => {
    const { validity, files: [file], } = event.target;
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (async (data)=>{
          setImage('http://api.tuimaadare.com/upload/'+data.singleUpload);
        }),
        onError: (er) => console.log(er),
      })
    }
  };

  const handleImages = (event) => {
    const { validity, files: [file], } = event.target;
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (async (data)=>{
          setImages([...images, 'http://api.tuimaadare.com/upload/'+data.singleUpload]);
        }),
        onError: (er) => console.log(er),
      })
    }
  };

  const handleCreate = (v) => {
    updateOneBlog({
      variables: {
        where: { id },
        data: {
          title: { set: v.title },
          topic: { set: v.topic },
          description: { set: v.description },
          img: { set: image },
          images: images,
        },
      },
    });
  };

  const deleteAdmin = () => {
    let isRight = window.confirm("Вы уверены, что хотите удалить данные?");
    if (isRight) deleteOneBlog({ variables: { where: { id } } });
  };

  if (loadingMe || loadingAdmin) return "Загрузка...";
  if (!user?.role?.canBlog) return "Ошибка";
  const blog = data?.findManyBlog[0];

  return (
    <>
      <Top title="Изменить данные" />
      <div style={{ maxWidth: 500 }}>
        <Form
          initialValues={{ ...blog }}
          onFinish={handleCreate}
          layout="vertical"
        >
          <Form.Item name="title" label="Заголовок" required>
            <Input />
          </Form.Item>
          <Form.Item name="topic" label="Топик" required>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Описание" required>
            <TextArea />
          </Form.Item>
          <div style={{ marginBottom: 24 }}>
            <label>Главное изображение:</label>
            <input
              onChange={handleImage}
              style={{ width: 96, marginBottom: 12 }}
              accept=".png, .jpg, .jpeg"
              name="myFile"
              type="file"
            />
            {image && (
              <a
                style={{ marginLeft: 8, marginRight: 8 }}
                target="blank"
                href={image}
              >
                <img style={{ height: 56, width: 56 }} src={image} />
              </a>
            )}
          </div>
          <div>
            <label>Изображения:</label>
            <input
              onChange={handleImages}
              style={{ width: 96 }}
              accept=".png, .jpg, .jpeg"
              name="myFile"
              type="file"
            />
            {images && (
              <div style={{ marginBottom: 12, marginTop: 12, display: "flex" }}>
                {images?.map((item, index) => {
                  return (
                    <a
                      key={index}
                      style={{ marginLeft: 8, marginRight: 8, display:"flex", flexDirection:"column", alignItems:"center" }}
                      target="blank"
                      href={item}
                    >
                      <img style={{ height: 56, width: 56, objectFit:"cover" }} src={item} />
                      <button onClick={(event)=>{
                        event.preventDefault();
                        let removeIndex = index
                        setImages(images.filter((_, index) => index !== removeIndex))
                        }}>Удалить</button>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          <Button
            loading={loading || loadingDel}
            htmlType="submit"
            type="primary"
          >
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button
            danger
            ghost
            loading={loading || loadingDel}
            onClick={deleteAdmin}
            type="primary"
          >
            Удалить
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateBlog;

import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_OBJECT } from '../../gqls'

const limit = 50
const Objects = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canObject) navigate(-1)
  })

  const { data, loading } = useQuery(FIND_MANY_OBJECT, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyObject : []
  const total = data ? data?.findManyObject.length : 0
  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Тип',
      dataIndex: 'objectType',
      key: 'objectType',
      render: (record) => record?.title
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Застройщик',
      dataIndex: 'developer',
      key: 'developer',
    },
    {
      title: 'Город',
      dataIndex: 'objectCity',
      key: 'objectCity',
      render: (record) => record?.title
    },
    {
      title: 'Изображения',
      dataIndex: 'images',
      key: 'images',
      render: (record) => record.map((item, index)=><a key={index} style={{marginLeft:8, marginRight:8}} target="blank" href={item}><img style={{height:56, width:56}} src={item} /></a>)
    },
  ]

  if (user?.role?.canObject) {
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/objects/update/${record.id}`}>Изменить</Link>,
    })
  }

  const getButtons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {user?.role?.canObject && <Link to={`/objects/create`}>Добавить</Link>}
    </div>
  )

  return (
    <>
      <Top title='Объекты' action={getButtons()} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default Objects

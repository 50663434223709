import { gql } from "@apollo/client"

export const FIND_UNIQUE_OBJECT_TYPE = gql`
	query(
		$where: ObjectTypeWhereUniqueInput!
	){
		findUniqueObjectType(
			where: $where
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const FIND_FIRST_OBJECT_TYPE = gql`
	query(
		$where: ObjectTypeWhereInput
		$orderBy: [ObjectTypeOrderByWithRelationInput]
		$cursor: ObjectTypeWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectTypeScalarFieldEnum]
	){
		findFirstObjectType(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const FIND_MANY_OBJECT_TYPE = gql`
	query(
		$where: ObjectTypeWhereInput
		$orderBy: [ObjectTypeOrderByWithRelationInput]
		$cursor: ObjectTypeWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectTypeScalarFieldEnum]
	){
		findManyObjectType(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const CREATE_ONE_OBJECT_TYPE = gql`
	mutation(
		$data: ObjectTypeCreateInput!
	){
		createOneObjectType(
			data: $data
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const UPDATE_ONE_OBJECT_TYPE = gql`
	mutation(
		$data: ObjectTypeUpdateInput!
		$where: ObjectTypeWhereUniqueInput!
	){
		updateOneObjectType(
			data: $data
			where: $where
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const DELETE_ONE_OBJECT_TYPE = gql`
	mutation(
		$where: ObjectTypeWhereUniqueInput!
	){
		deleteOneObjectType(
			where: $where
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
export const UPSERT_ONE_OBJECT_TYPE = gql`
	mutation(
		$where: ObjectTypeWhereUniqueInput!
		$create: ObjectTypeCreateInput!
		$update: ObjectTypeUpdateInput!
	){
		upsertOneObjectType(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			title
			objects{
				id
				isVisible
				title
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCity{
					id
					isVisible
					title
					description
					images
					countryId
				}
				objectCityId
				objectTypeId
			}
			_count{
				objects
			}
		}
	}
`
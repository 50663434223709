import styled from "styled-components"
import { Image as AntImage } from "antd"

const Img = styled(AntImage)`
    object-fit: contain;
`

export const Image = (props) => {
    return (
        <Img
            {...props}
            onError={event => {
                event.target.src = "/images/no-image.png"
                event.onerror = null
            }}
        />
    )
}
import { gql } from "@apollo/client"

export const SINGLE_UPLOAD = gql`
	mutation(
		$upload: Upload!
	){
		singleUpload(
			upload: $upload
		)
	}
`
export const MULTI_UPLOAD = gql`
	mutation(
		$upload: [Upload]!
	){
		multiUpload(
			upload: $upload
		)
	}
`
export const DELETE_FILE = gql`
	mutation(
		$fileName: String!
	){
		deleteFile(
			fileName: $fileName
		)
	}
`
import { gql } from "@apollo/client"

export const AGGREGATE_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				isConfirmed
				name
				phone
				_all
			}
			_min{
				id
				isConfirmed
				name
				phone
			}
			_max{
				id
				isConfirmed
				name
				phone
			}
		}
	}
`
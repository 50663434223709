import { gql } from "@apollo/client"

export const DELETE_MANY_USER = gql`
	mutation(
		$where: UserWhereInput
	){
		deleteManyUser(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_USER = gql`
	mutation(
		$data: UserUpdateManyMutationInput!
		$where: UserWhereInput
	){
		updateManyUser(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_OBJECT = gql`
	mutation(
		$where: ObjectWhereInput
	){
		deleteManyObject(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_OBJECT = gql`
	mutation(
		$data: ObjectUpdateManyMutationInput!
		$where: ObjectWhereInput
	){
		updateManyObject(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_BLOG = gql`
	mutation(
		$where: BlogWhereInput
	){
		deleteManyBlog(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_BLOG = gql`
	mutation(
		$data: BlogUpdateManyMutationInput!
		$where: BlogWhereInput
	){
		updateManyBlog(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_REVIEW = gql`
	mutation(
		$where: ReviewWhereInput
	){
		deleteManyReview(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_REVIEW = gql`
	mutation(
		$data: ReviewUpdateManyMutationInput!
		$where: ReviewWhereInput
	){
		updateManyReview(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_APPLICATION = gql`
	mutation(
		$where: ApplicationWhereInput
	){
		deleteManyApplication(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_APPLICATION = gql`
	mutation(
		$data: ApplicationUpdateManyMutationInput!
		$where: ApplicationWhereInput
	){
		updateManyApplication(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_ADMIN = gql`
	mutation(
		$where: AdminWhereInput
	){
		deleteManyAdmin(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN = gql`
	mutation(
		$data: AdminUpdateManyMutationInput!
		$where: AdminWhereInput
	){
		updateManyAdmin(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_ADMIN_ROLE = gql`
	mutation(
		$where: AdminRoleWhereInput
	){
		deleteManyAdminRole(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN_ROLE = gql`
	mutation(
		$data: AdminRoleUpdateManyMutationInput!
		$where: AdminRoleWhereInput
	){
		updateManyAdminRole(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_OBJECT_TYPE = gql`
	mutation(
		$where: ObjectTypeWhereInput
	){
		deleteManyObjectType(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_OBJECT_TYPE = gql`
	mutation(
		$data: ObjectTypeUpdateManyMutationInput!
		$where: ObjectTypeWhereInput
	){
		updateManyObjectType(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_COUNTRY = gql`
	mutation(
		$where: CountryWhereInput
	){
		deleteManyCountry(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_COUNTRY = gql`
	mutation(
		$data: CountryUpdateManyMutationInput!
		$where: CountryWhereInput
	){
		updateManyCountry(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const DELETE_MANY_CITIES = gql`
	mutation(
		$where: CitiesWhereInput
	){
		deleteManyCities(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_CITIES = gql`
	mutation(
		$data: CitiesUpdateManyMutationInput!
		$where: CitiesWhereInput
	){
		updateManyCities(
			data: $data
			where: $where
		){
			count
		}
	}
`
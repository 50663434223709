import { gql } from "@apollo/client"

export const FIND_UNIQUE_COUNTRY = gql`
	query(
		$where: CountryWhereUniqueInput!
	){
		findUniqueCountry(
			where: $where
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
export const FIND_FIRST_COUNTRY = gql`
	query(
		$where: CountryWhereInput
		$orderBy: [CountryOrderByWithRelationInput]
		$cursor: CountryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CountryScalarFieldEnum]
	){
		findFirstCountry(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
export const FIND_MANY_COUNTRY = gql`
	query(
		$where: CountryWhereInput
		$orderBy: [CountryOrderByWithRelationInput]
		$cursor: CountryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CountryScalarFieldEnum]
	){
		findManyCountry(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
export const CREATE_ONE_COUNTRY = gql`
	mutation(
		$data: CountryCreateInput!
	){
		createOneCountry(
			data: $data
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
export const UPDATE_ONE_COUNTRY = gql`
	mutation(
		$data: CountryUpdateInput!
		$where: CountryWhereUniqueInput!
	){
		updateOneCountry(
			data: $data
			where: $where
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
export const DELETE_ONE_COUNTRY = gql`
	mutation(
		$where: CountryWhereUniqueInput!
	){
		deleteOneCountry(
			where: $where
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
export const UPSERT_ONE_COUNTRY = gql`
	mutation(
		$where: CountryWhereUniqueInput!
		$create: CountryCreateInput!
		$update: CountryUpdateInput!
	){
		upsertOneCountry(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			title
			description
			currency
			locale
			images
			City{
				id
				isVisible
				title
				description
				images
				countryId
				objects{
					id
					isVisible
					title
					description
					images
					about
					aboutImg
					developer
					address
					space
					rooms
					bedrooms
					bathrooms
					costUSD
					costRUB
					costAED
					objectCityId
					objectTypeId
				}
				_count{
					objects
				}
			}
			_count{
				City
			}
		}
	}
`
import { gql } from "@apollo/client"

export const FIND_UNIQUE_OBJECT = gql`
	query(
		$where: ObjectWhereUniqueInput!
	){
		findUniqueObject(
			where: $where
		){
			id
			isVisible
			title
			description
			images
			about
			aboutImg
			developer
			address
			space
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
export const FIND_FIRST_OBJECT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findFirstObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			isVisible
			title
			description
			images
			about
			aboutImg
			developer
			address
			space
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
export const FIND_MANY_OBJECT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findManyObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			sellType
			description
			images
			about
			aboutImg
			developer
			address
			space
			spaceFt
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
export const CREATE_ONE_OBJECT = gql`
	mutation(
		$data: ObjectCreateInput!
	){
		createOneObject(
			data: $data
		){
			id
			isVisible
			title
			description
			images
			about
			aboutImg
			developer
			address
			space
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
export const UPDATE_ONE_OBJECT = gql`
	mutation(
		$data: ObjectUpdateInput!
		$where: ObjectWhereUniqueInput!
	){
		updateOneObject(
			data: $data
			where: $where
		){
			id
			isVisible
			title
			description
			images
			about
			aboutImg
			developer
			address
			space
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
export const DELETE_ONE_OBJECT = gql`
	mutation(
		$where: ObjectWhereUniqueInput!
	){
		deleteOneObject(
			where: $where
		){
			id
			isVisible
			title
			description
			images
			about
			aboutImg
			developer
			address
			space
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
export const UPSERT_ONE_OBJECT = gql`
	mutation(
		$where: ObjectWhereUniqueInput!
		$create: ObjectCreateInput!
		$update: ObjectUpdateInput!
	){
		upsertOneObject(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			title
			description
			images
			about
			aboutImg
			developer
			address
			space
			rooms
			bedrooms
			bathrooms
			costUSD
			costRUB
			costAED
			objectCity{
				id
				isVisible
				title
				description
				images
				country{
					id
					isVisible
					title
					description
					currency
					locale
					images
				}
				countryId
				_count{
					objects
				}
			}
			objectCityId
			objectType{
				id
				isVisible
				title
				_count{
					objects
				}
			}
			objectTypeId
		}
	}
`
import { gql } from "@apollo/client"

export const AGGREGATE_BLOG = gql`
	query(
		$where: BlogWhereInput
		$orderBy: [BlogOrderByWithRelationInput]
		$cursor: BlogWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateBlog(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				isVisible
				title
				topic
				img
				description
				images
				_all
			}
			_min{
				id
				isVisible
				title
				topic
				img
				description
			}
			_max{
				id
				isVisible
				title
				topic
				img
				description
			}
		}
	}
`
import { useEffect, useState } from 'react'
import { Button, Form, message, Input } from 'antd'
import {Top} from '../../components/Top'
import { useMutation } from '@apollo/client'
import { useUser } from '../../utils/hooks'

import { useNavigate } from 'react-router-dom'
import { CREATE_ONE_BLOG, SINGLE_UPLOAD } from '../../gqls'

const { TextArea } = Input

const CreateBlog = () => {
  const { user, loading: loadingMe } = useUser()
  const navigate = useNavigate()

  const [image, setImage] = useState(null)
  const [images, setImages] = useState([])

  useEffect(() => {
    if (!user?.role?.canBlog) navigate(-1)
  })

  const [createOneBlog, { loading }] = useMutation(CREATE_ONE_BLOG, {
    onCompleted: () => navigate('/blogs'),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const [upload] = useMutation(SINGLE_UPLOAD)

  const handleCreate = (v) => {
    createOneBlog({ variables: { data: { ...v, img:image, images:images } } })
  }

  const handleImage = (event) => {
    const { validity, files: [file], } = event.target;
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (async (data)=>{
          setImage('http://api.tuimaadare.com/upload/'+data.singleUpload);
        }),
        onError: (er) => console.log(er),
      })
    }
  };

  const handleImages = (event) => {
    const { validity, files: [file], } = event.target;
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (async (data)=>{
          setImages([...images, 'http://api.tuimaadare.com/upload/'+data.singleUpload]);
        }),
        onError: (er) => console.log(er),
      })
    }
  };

  if (loadingMe) return 'Загрузка...'
  if (!user?.role?.canBlog) return 'Ошибка'

  return (
    <>
      <Top title='Добавить блог' />
      <div style={{ maxWidth: 500 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Заголовок' required>
            <Input />
          </Form.Item>
          <Form.Item name='topic' label='Топик' required>
            <Input />
          </Form.Item>
          <Form.Item name='description' label='Описание' required>
            <TextArea />
          </Form.Item>
          <div style={{marginBottom:24}}>
            <label>Главное изображение:</label>
            <input onChange={handleImage} style={{width:96, marginBottom:12}} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
              {image && <a style={{marginLeft:8, marginRight:8}} target="blank" href={image}><img style={{height:56, width:56}} src={image} /></a>}
          </div>
          <div>
          <label>Изображения:</label>
          <input onChange={handleImages} style={{width:96}} accept='.png, .jpg, .jpeg' name='myFile' type='file' />
          {images && <div style={{marginBottom:12, marginTop:12, display:'flex'}}>
              {
                images?.map((item, index)=>{
                  return(
                    <a key={index} style={{marginLeft:8, marginRight:8, display:"flex", flexDirection:"column", alignItems:"center" }} target="blank" href={item}><img style={{height:56, width:56}} src={item} /><button onClick={(event)=>{event.preventDefault(); let removeIndex = index; setImages(images.filter((_, index) => index !== removeIndex))}}>Удалить</button></a>
                  )
                })
              }
              </div>}
              </div>
          <Button loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateBlog

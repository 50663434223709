import { useEffect } from 'react'
import { Button, Form, message, Input, Checkbox } from 'antd'
import {Top} from '../../components/Top'
import { useMutation } from '@apollo/client'
import { useUser } from '../../utils/hooks'
import { CREATE_ONE_ADMIN_ROLE } from '../../gqls'
import { useNavigate } from 'react-router-dom'
const { TextArea } = Input
const requiredRule = { required: true }

const CreateRole = () => {
  const { user, loading: loadingMe } = useUser()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user?.role?.canChangeRole) navigate(-1)
  })

  const [createOneAdminRole, { loading }] = useMutation(CREATE_ONE_ADMIN_ROLE, {
    onCompleted: () => navigate(-1),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const handleCreate = (v) => {
    createOneAdminRole({ variables: { data: v } })
  }

  if (loadingMe) return 'Загрузка...'
  if (!user?.role?.canChangeRole) return 'Ошибка'
  return (
    <>
      <Top title='Добавить роль' />
      <div style={{ maxWidth: 700 }}>
        <Form onFinish={handleCreate} layout='vertical'>
          <Form.Item name='title' label='Название' rules={[requiredRule]}>
            <Input />
          </Form.Item>
          <Form.Item name='description' label='Описание'>
            <TextArea rows={4} />
          </Form.Item>
          <h3>Права:</h3>
      
          <Form.Item valuePropName='checked' name='canObject'>
            <Checkbox>К объектам</Checkbox>
          </Form.Item>
          <Form.Item valuePropName='checked' name='canBlog'>
            <Checkbox>К блогу</Checkbox>
          </Form.Item>
          <Form.Item valuePropName='checked' name='canReview'>
            <Checkbox>К отзывам</Checkbox>
          </Form.Item>
          <Form.Item valuePropName='checked' name='canChangeRole'>
            <Checkbox>К админам</Checkbox>
          </Form.Item>

          <Button loading={loading} htmlType='submit' type='primary'>
            Добавить
          </Button>
        </Form>
      </div>
    </>
  )
}

export default CreateRole

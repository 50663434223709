import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_BLOG } from '../../gqls'

const limit = 50
const Blogs = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canBlog) navigate(-1)
  })

  const { data, loading } = useQuery(FIND_MANY_BLOG, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyBlog : []
  const total = data ? data?.findManyBlog.length : 0
  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Топик',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Главное изображение',
      dataIndex: 'img',
      key: 'img',
      render: (record) => <a style={{marginLeft:8, marginRight:8}} target="blank" href={record}><img style={{height:56, width:56}} src={record} /></a>
    },
    {
      title: 'Изображения',
      dataIndex: 'images',
      key: 'images',
      render: (record) => record.map((item, index)=><a key={index} style={{marginLeft:8, marginRight:8}} target="blank" href={item}><img style={{height:56, width:56}} src={item} /></a>)
    },
  ]

  if (user?.role?.canBlog) {
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/blogs/update/${record.id}`}>Изменить</Link>,
    })
  }

  const getButtons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {user?.role?.canBlog && <Link to={`/blogs/create`}>Добавить</Link>}
    </div>
  )

  return (
    <>
      <Top title='Блог' action={getButtons()} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default Blogs

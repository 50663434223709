import { gql } from "@apollo/client"

export const AGGREGATE_CITIES = gql`
	query(
		$where: CitiesWhereInput
		$orderBy: [CitiesOrderByWithRelationInput]
		$cursor: CitiesWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateCities(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				isVisible
				title
				description
				images
				countryId
				_all
			}
			_min{
				id
				isVisible
				title
				description
				countryId
			}
			_max{
				id
				isVisible
				title
				description
				countryId
			}
		}
	}
`
import { gql } from "@apollo/client"

export const AGGREGATE_ADMIN = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateAdmin(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
				_all
			}
			_min{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
			_max{
				id
				block
				delete
				isConfirmed
				type
				email
				name
				password
				repassword
				roleId
			}
		}
	}
`
import { useEffect, useState } from "react";
import { Button, Form, message, Input, Select, InputNumber } from "antd";
import { Top } from "../../components/Top";
import { useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../utils/hooks";

import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_ONE_OBJECT,
  FIND_MANY_CITIES,
  FIND_MANY_OBJECT,
  FIND_MANY_OBJECT_TYPE,
  SINGLE_UPLOAD,
  UPDATE_ONE_OBJECT,
} from "../../gqls";

const { TextArea } = Input
const { Option } = Select

const UpdateObject = () => {
  // const { id } = match.params
  const { user, loading: loadingMe } = useUser();

  const { id } = useParams();
  const navigate = useNavigate();

  const [image, setImage] = useState(null)
  const [images, setImages] = useState([])

  useEffect(() => {
    if (!user?.role?.canBlog) navigate(-1);
  });

  const { data, loading: loadingAdmin } = useQuery(FIND_MANY_OBJECT, {
    fetchPolicy: "network-only",
    variables: { where: { id: { equals: id } } },
    onCompleted:(data)=>{
      setImages(data.findManyObject[0].images)
    }
  });


  const { data: dataType, loading: loadType } = useQuery(FIND_MANY_OBJECT_TYPE);
  const { data: dataCity, loading: loadCity } = useQuery(FIND_MANY_CITIES);

  const onCompleted = () => navigate(-1);

  const onError = (err) => {
    console.error(err);
    message.error("Не удалось выполнить запрос");
  };

  const [updateOneObject, { loading }] = useMutation(UPDATE_ONE_OBJECT, {
    onCompleted,
    onError,
  });

  const [deleteOneObject, { loading: loadingDel }] = useMutation(
    DELETE_ONE_OBJECT,
    {
      onCompleted,
      onError,
    }
  );

  const [upload] = useMutation(SINGLE_UPLOAD)

  const handleImages = (event) => {
    const { validity, files: [file], } = event.target;
    if (validity.valid) {
      upload({
        variables: { upload: file },
        onCompleted: (async (data)=>{
          setImages([...images, 'http://api.tuimaadare.com/upload/'+data.singleUpload]);
        }),
        onError: (er) => console.log(er),
      })
    }
  };

  const handleCreate = (v) => {
    updateOneObject({
      variables: {
        where: { id },
        data: {
          title:{set:v.title},
          sellType:{set:v.sellType},
          objectType:{
            connect:{
              id:v.type
            }
          },
          description:{set:v.description},
          about:{set:v.about},
          developer:{set:v.developer},
          address:{set:v.address},
          space:{set:v.space},
          spaceFt:{set:v.spaceFt},
          rooms:{set:v.rooms},
          bedrooms:{set:v.bedrooms},
          bathrooms:{set:v.bathrooms},
          costRUB:{set:v.costRUB},
          costUSD:{set:v.costUSD},
          costAED:{set:v.costAED},
          objectCity: {
            connect:{
              id:v.city
            }
          },
          images: images,
        },
      },
    });
  };

  const deleteAdmin = () => {
    let isRight = window.confirm("Вы уверены, что хотите удалить данные?");
    if (isRight) deleteOneObject({ variables: { where: { id } } });
  };

  if (loadingMe || loadingAdmin) return "Загрузка...";
  if (!user?.role?.canBlog) return "Ошибка";
  const blog = data?.findManyObject[0];

  return (
    <>
      <Top title="Изменить данные" />
      <div style={{ maxWidth: 500 }}>
        <Form
          initialValues={{ ...blog, type: blog?.objectType?.id, city: blog?.objectCity?.id}}
          onFinish={handleCreate}
          layout="vertical"
        >
    <Form.Item name='title' label='Заголовок' required>
            <Input />
          </Form.Item>
          <Form.Item name='sellType' label='Тип продажи' required>
            <Select placeholder='Выберите тип продажи объекта'>
              <Option value={"OFFPLAN"}>Оффплан</Option>
              <Option value={"SECONDARY"}>Вторичка</Option>
              <Option value={"RENT"}>Аренда</Option>
            </Select>
          </Form.Item>

          <Form.Item name="type" label="Тип" required>
            <Select placeholder="Выберите тип объекта">
              {dataType?.findManyObjectType?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.title}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item name='description' label='Описание' required>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name='about' label='Об объекте' required>
            <Input />
          </Form.Item>
          <Form.Item name='developer' label='Застройщик' required>
            <Input />
          </Form.Item>
          <Form.Item name='address' label='Адрес' required>
            <Input />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Form.Item name="space" label="Площадь (в м2)" required>
                <InputNumber />
              </Form.Item>
              <Form.Item name="rooms" label="Комнат" required>
                <InputNumber />
              </Form.Item>
            </div>
            <div>
              <div style={{height:86}}></div>
            <Form.Item name="bedrooms" label="Спален" required>
                <InputNumber />
              </Form.Item>
            </div>
            <div>
            <Form.Item name="spaceFt" label="Площадь (в ft2)" required>
                <InputNumber />
              </Form.Item>
   
              <Form.Item name="bathrooms" label="Санузлов" required>
                <InputNumber />
              </Form.Item>
            </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
          <Form.Item name='costRUB' label='Цена в рублях' required>
            <InputNumber />
          </Form.Item>
          <Form.Item name='costUSD' label='Цена в долларах' required>
            <InputNumber />
          </Form.Item>
          <Form.Item name='costAED' label='Цена в дирхамах' required>
            <InputNumber />
          </Form.Item>
          </div>
            <Form.Item name="city" label="Город" required>
            <Select placeholder="Выберите город">
              {dataCity?.findManyCities?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.title}</Option>;
              })}
            </Select>
          </Form.Item>
  
          <div>
            <label>Изображения:</label>
            <input
              onChange={handleImages}
              style={{ width: 96 }}
              accept=".png, .jpg, .jpeg"
              name="myFile"
              type="file"
            />
            {images && (
              <div style={{ marginBottom: 12, marginTop: 12, display: "flex" }}>
                {images?.map((item, index) => {
                  return (
                    <a
                      key={index}
                      style={{ marginLeft: 8, marginRight: 8, display:"flex", flexDirection:"column", alignItems:"center" }}
                      target="blank"
                      href={item}
                    >
                      <img style={{ height: 56, width: 56, objectFit:"cover" }} src={item} />
                      <button onClick={(event)=>{
                        event.preventDefault();
                        let removeIndex = index
                        setImages(images.filter((_, index) => index !== removeIndex))
                        }}>Удалить</button>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          <Button
            loading={loading || loadingDel}
            htmlType="submit"
            type="primary"
          >
            Изменить
          </Button>
        </Form>
        <div style={{ paddingTop: 30 }}>
          <hr />
          <Button
            danger
            ghost
            loading={loading || loadingDel}
            onClick={deleteAdmin}
            type="primary"
          >
            Удалить
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateObject;

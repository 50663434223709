import { gql } from "@apollo/client"

export const AGGREGATE_OBJECT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				isVisible
				title
				sellType
				type
				description
				images
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				city
				objectCityId
				objectTypeId
				_all
			}
			_avg{
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
			}
			_sum{
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
			}
			_min{
				id
				isVisible
				title
				description
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectTypeId
			}
			_max{
				id
				isVisible
				title
				description
				about
				aboutImg
				developer
				address
				space
				rooms
				bedrooms
				bathrooms
				costUSD
				costRUB
				costAED
				objectCityId
				objectTypeId
			}
		}
	}
`
import { Table } from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import {Top} from '../../components/Top'
import { useState, useEffect } from 'react'
import { useUser } from '../../utils/hooks'
import { FIND_MANY_REVIEW } from '../../gqls'

const limit = 50
const Reviews = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (!user?.role?.canReview) navigate(-1)
  })

  const { data, loading } = useQuery(FIND_MANY_REVIEW, {
    fetchPolicy: 'network-only',
    variables: { take: limit, skip },
  })

  const onChangeTable = (pagination) => {
    setSkip((pagination.current - 1) * limit)
  }

  const record = data ? data?.findManyReview : []
  const total = data ? data?.findManyReview.length : 0
  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Рейтинг',
      dataIndex: 'rating',
      key: 'rating',
    },
  ]

  if (user?.role?.canReview) {
    columns.push({
      title: 'Действие',
      key: 'operation',
      align: 'center',
      render: (record) => <Link to={`/reviews/update/${record.id}`}>Изменить</Link>,
    })
  }

  const getButtons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {user?.role?.canReview && <Link to={`/reviews/create`}>Добавить</Link>}
    </div>
  )

  return (
    <>
      <Top title='Отзывы' action={getButtons()} />
      <Table
        dataSource={record}
        loading={loading}
        onChange={onChangeTable}
        pagination={{
          total,
          pageSize: limit,
        }}
        scroll={{ x: 600 }}
        rowKey={(row) => row.id}
        columns={columns}
      />
    </>
  )
}

export default Reviews

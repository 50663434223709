import { gql } from "@apollo/client"

export const AGGREGATE_APPLICATION = gql`
	query(
		$where: ApplicationWhereInput
		$orderBy: [ApplicationOrderByWithRelationInput]
		$cursor: ApplicationWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateApplication(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				isVisible
				name
				number
				email
				callTime
				objectOfInterest
				commuticationType
				_all
			}
			_min{
				id
				isVisible
				name
				number
				email
				callTime
				objectOfInterest
			}
			_max{
				id
				isVisible
				name
				number
				email
				callTime
				objectOfInterest
			}
		}
	}
`
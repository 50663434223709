import { gql } from "@apollo/client"

export const FIND_MANY_USER_COUNT = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUserCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_OBJECT_COUNT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findManyObjectCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_BLOG_COUNT = gql`
	query(
		$where: BlogWhereInput
		$orderBy: [BlogOrderByWithRelationInput]
		$cursor: BlogWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [BlogScalarFieldEnum]
	){
		findManyBlogCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_REVIEW_COUNT = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ReviewScalarFieldEnum]
	){
		findManyReviewCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_APPLICATION_COUNT = gql`
	query(
		$where: ApplicationWhereInput
		$orderBy: [ApplicationOrderByWithRelationInput]
		$cursor: ApplicationWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ApplicationScalarFieldEnum]
	){
		findManyApplicationCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_ADMIN_COUNT = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdminCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_ADMIN_ROLE_COUNT = gql`
	query(
		$where: AdminRoleWhereInput
		$orderBy: [AdminRoleOrderByWithRelationInput]
		$cursor: AdminRoleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminRoleScalarFieldEnum]
	){
		findManyAdminRoleCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_OBJECT_TYPE_COUNT = gql`
	query(
		$where: ObjectTypeWhereInput
		$orderBy: [ObjectTypeOrderByWithRelationInput]
		$cursor: ObjectTypeWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectTypeScalarFieldEnum]
	){
		findManyObjectTypeCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_COUNTRY_COUNT = gql`
	query(
		$where: CountryWhereInput
		$orderBy: [CountryOrderByWithRelationInput]
		$cursor: CountryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CountryScalarFieldEnum]
	){
		findManyCountryCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_MANY_CITIES_COUNT = gql`
	query(
		$where: CitiesWhereInput
		$orderBy: [CitiesOrderByWithRelationInput]
		$cursor: CitiesWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CitiesScalarFieldEnum]
	){
		findManyCitiesCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
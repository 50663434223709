import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu as AntMenu } from 'antd'
import { useUser } from '../utils/hooks'

export const Menu = () => {
    const { pathname } = window.location
    const { user } = useUser()

    return (
        <AntMenu theme="dark" mode="inline" defaultSelectedKeys={[pathname]}>
            <AntMenu.Item key={`/`}>
                <MenuLink to={`/`}>
                    Главная
                </MenuLink>
            </AntMenu.Item>
            {
            <AntMenu.Item key={`/application`}>
                <MenuLink to={`/application`}>
                    Лиды
                </MenuLink>
            </AntMenu.Item>
            }
            {user?.role?.canObject &&
            <AntMenu.Item key={`/objects`}>
                <MenuLink to={`/objects`}>
                    Объекты
                </MenuLink>
            </AntMenu.Item>
            }
            {user?.role?.canObject &&
            <AntMenu.Item key={`/country`}>
                <MenuLink to={`/country`}>
                    Страны
                </MenuLink>
            </AntMenu.Item>
            }
            {user?.role?.canObject &&
            <AntMenu.Item key={`/objecttype`}>
                <MenuLink to={`/objecttype`}>
                    Типы объектов
                </MenuLink>
            </AntMenu.Item>
            }
            {user?.role?.canBlog &&
            <AntMenu.Item key={`/blogs`}>
                <MenuLink to={`/blogs`}>
                    Блог
                </MenuLink>
            </AntMenu.Item>
            }
            {user?.role?.canReview &&
            <AntMenu.Item key={`/reviews`}>
                <MenuLink to={`/reviews`}>
                    Отзывы
                </MenuLink>
            </AntMenu.Item>
            }
            {user?.role?.canChangeRole &&
            <AntMenu.Item key={`/admins`}>
                <MenuLink to={`/admins`}>
                    Администраторы
                </MenuLink>
            </AntMenu.Item>
            }
        </AntMenu>
    )
}

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`

import { gql } from "@apollo/client"

export const FIND_UNIQUE_REVIEW = gql`
	query(
		$where: ReviewWhereUniqueInput!
	){
		findUniqueReview(
			where: $where
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
export const FIND_FIRST_REVIEW = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ReviewScalarFieldEnum]
	){
		findFirstReview(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
export const FIND_MANY_REVIEW = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ReviewScalarFieldEnum]
	){
		findManyReview(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
export const CREATE_ONE_REVIEW = gql`
	mutation(
		$data: ReviewCreateInput!
	){
		createOneReview(
			data: $data
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
export const UPDATE_ONE_REVIEW = gql`
	mutation(
		$data: ReviewUpdateInput!
		$where: ReviewWhereUniqueInput!
	){
		updateOneReview(
			data: $data
			where: $where
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
export const DELETE_ONE_REVIEW = gql`
	mutation(
		$where: ReviewWhereUniqueInput!
	){
		deleteOneReview(
			where: $where
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
export const UPSERT_ONE_REVIEW = gql`
	mutation(
		$where: ReviewWhereUniqueInput!
		$create: ReviewCreateInput!
		$update: ReviewUpdateInput!
	){
		upsertOneReview(
			where: $where
			create: $create
			update: $update
		){
			id
			isVisible
			title
			description
			rating
		}
	}
`
import { gql } from "@apollo/client"

export const AGGREGATE_REVIEW = gql`
	query(
		$where: ReviewWhereInput
		$orderBy: [ReviewOrderByWithRelationInput]
		$cursor: ReviewWhereUniqueInput
		$take: Int
		$skip: Int
	){
		aggregateReview(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
		){
			_count{
				id
				createdAt
				updatedAt
				isVisible
				title
				description
				rating
				_all
			}
			_avg{
				rating
			}
			_sum{
				rating
			}
			_min{
				id
				isVisible
				title
				description
				rating
			}
			_max{
				id
				isVisible
				title
				description
				rating
			}
		}
	}
`